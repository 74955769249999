const storageAfterBillingData = [
    {
        label: "Armazém VDM Santa Catarina",
        value: "ARMAZEM_SANTA_CATARINA"
    },
    {
        label: "Armazém VDM Itapevi",
        value: "ARMAZEM_ITAPEVI"
    },
    {
        label: "Cliente",
        value: "CLIENTE"
    }
];

export default storageAfterBillingData;